import { ReactNode } from 'react'

import { ButtonLink, Link } from 'common/UI'
import { IconOptions } from 'common/UI/Icon'
import { ButtonVariant, ButtonSize } from 'common/UI/OldButton'
import { TextLinkStoryblok } from 'common/types'
import { GTMEvents, trackEvent } from 'common/utils/gtm'
import { removeLeadingAndTrailingSlash } from 'common/utils/string'

type Props = {
  href: TextLinkStoryblok['link']
  target?: '_blank' | '_self'
  linkStyle?: 'text' | 'button'
  variant?: ButtonVariant
  size?: ButtonSize
  isFull?: boolean
  leftIcon?: IconOptions
  rightIcon?: IconOptions
  onClick?: () => void
  className?: string
  children: ReactNode
}

export const CMSLink: React.FC<Props> = ({
  href,
  linkStyle = 'button',
  variant = 'solid',
  size = 'default',
  isFull = false,
  leftIcon,
  rightIcon,
  children,
  onClick,
  ...props
}) => {
  const linkProps: Pick<Props, 'leftIcon' | 'rightIcon' | 'onClick'> = {
    leftIcon,
    rightIcon,
    ...props,
  }
  const buttonProps = {
    ...linkProps,
    variant,
    size,
    isFull,
  }

  if (!href) return null

  const onExternalLinkClick = () => {
    onClick?.()

    if (href.cached_url?.startsWith('tel:')) {
      trackEvent(GTMEvents.Call, {
        'tfp-phone-number': href.cached_url.replace('tel:', ''),
      })
    }
  }

  if (href.linktype === 'story') {
    const url = `/${removeLeadingAndTrailingSlash(
      href.story ? href.story.url : href.cached_url
    )}`
    // internal
    return (
      <>
        {linkStyle === 'button' ? (
          <ButtonLink href={url} {...buttonProps}>
            {children}
          </ButtonLink>
        ) : (
          <Link href={url} {...linkProps}>
            {children}
          </Link>
        )}
      </>
    )
  }

  if (href.linktype === 'url') {
    // anchor
    if (href.url && href.url[0] === '#') {
      return (
        <ButtonLink
          rel="noopener noreferrer"
          href={href.cached_url}
          {...buttonProps}
        >
          {children}
        </ButtonLink>
      )
    }

    // external
    return linkStyle === 'button' ? (
      <ButtonLink
        target="_blank"
        rel="noopener noreferrer nofollow"
        href={href.cached_url}
        onClick={onExternalLinkClick}
        {...buttonProps}
      >
        {children}
      </ButtonLink>
    ) : (
      <Link
        target="_blank"
        rel="noopener noreferrer nofollow"
        href={href.cached_url}
        onClick={onExternalLinkClick}
        {...linkProps}
      >
        {children}
      </Link>
    )
  }

  if (href.linktype === 'email') {
    // email
    return linkStyle === 'button' ? (
      <ButtonLink href={`mailto:${href.email}`} {...buttonProps}>
        {children}
      </ButtonLink>
    ) : (
      <Link href={`mailto:${href.email}`} {...linkProps}>
        {children}
      </Link>
    )
  }

  throw new Error(`Unexpected link type received: ${JSON.stringify(href)}`)
}
