import ContentLoader from 'react-content-loader'
import styled, { useTheme } from 'styled-components'

import { addAlphaToColor } from 'common/utils/style'

export const Loader = (): JSX.Element => {
  const theme = useTheme()

  return (
    <div className="py-8">
      <MobileOnly>
        <ContentLoader
          backgroundColor={addAlphaToColor(theme.colors.foreground.subtle, 40)}
          foregroundColor={addAlphaToColor(theme.colors.foreground.subtle, 10)}
          opacity={0.25}
          viewBox="0 0 380 357"
        >
          <rect x="0" y="0" rx="4" ry="4" width="380" height="140" />
          <rect x="0" y="145" rx="1" ry="1" width="15" height="5" />
          <rect x="20" y="145" rx="1" ry="1" width="40" height="5" />
          <rect x="0" y="155" rx="2" ry="2" width="380" height="10" />

          <rect x="0" y="190" rx="4" ry="4" width="380" height="140" />
          <rect x="0" y="335" rx="1" ry="1" width="15" height="5" />
          <rect x="20" y="335" rx="1" ry="1" width="40" height="5" />
          <rect x="0" y="345" rx="2" ry="2" width="380" height="10" />
        </ContentLoader>
      </MobileOnly>
      <DesktopOnly>
        <ContentLoader
          backgroundColor={addAlphaToColor(theme.colors.foreground.subtle, 40)}
          foregroundColor={addAlphaToColor(theme.colors.foreground.subtle, 10)}
          opacity={0.25}
          viewBox="0 0 380 190"
        >
          <rect x="0" y="0" rx="4" ry="4" width="111" height="70" />
          <rect x="0" y="75" rx="1" ry="1" width="15" height="3" />
          <rect x="20" y="75" rx="1" ry="1" width="40" height="3" />
          <rect x="0" y="82" rx="2" ry="2" width="111" height="5" />
          <rect x="0" y="92" rx="2" ry="2" width="80" height="5" />

          <rect x="134.5" y="0" rx="4" ry="4" width="111" height="70" />
          <rect x="134.5" y="75" rx="1" ry="1" width="15" height="3" />
          <rect x="154.5" y="75" rx="1" ry="1" width="40" height="3" />
          <rect x="134.5" y="82" rx="2" ry="2" width="111" height="5" />
          <rect x="134.5" y="92" rx="2" ry="2" width="80" height="5" />

          <rect x="269" y="0" rx="4" ry="4" width="111" height="70" />
          <rect x="269" y="75" rx="1" ry="1" width="15" height="3" />
          <rect x="289" y="75" rx="1" ry="1" width="40" height="3" />
          <rect x="269" y="82" rx="2" ry="2" width="111" height="5" />
          <rect x="269" y="92" rx="2" ry="2" width="80" height="5" />

          <rect x="0" y="120" rx="4" ry="4" width="111" height="70" />
          <rect x="134.5" y="120" rx="4" ry="4" width="111" height="70" />
          <rect x="269" y="120" rx="4" ry="4" width="111" height="70" />
        </ContentLoader>
      </DesktopOnly>
    </div>
  )
}

const MobileOnly = styled.div`
  ${({ theme }) => theme.media.md} {
    display: none;
  }
`

const DesktopOnly = styled.div`
  display: none;

  ${({ theme }) => theme.media.md} {
    display: block;
  }
`
