import dayjs, { extend as dayJsExtend } from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { ISbStoryData } from 'storyblok-js-client'
import styled from 'styled-components'

import { Icon, Text, ButtonLink, Tag } from 'common/UI'
import { OpenEveningStoryblok } from 'common/types'
import { GTMEvents, trackEvent } from 'common/utils/gtm'
import {
  removeLeadingAndTrailingSlash,
  toCapitalize,
} from 'common/utils/string'
import { addAlphaToColor } from 'common/utils/style'
import { useTranslation } from 'lib/i18n'

dayJsExtend(customParseFormat)

type Props = {
  item: ISbStoryData<OpenEveningStoryblok>
  variant?: 'big' | 'small'
}

export const OpenEveningThumb = ({
  item,
  variant = 'small',
  ...props
}: Props): JSX.Element => {
  const { i18n, locale } = useTranslation()

  const handleTrackEvent = () => {
    trackEvent(GTMEvents.OpenEvening, {
      'tfp-title': item.content.title || 'n/a',
      'tfp-location': item.content.location || 'n/a',
      'tfp-date-time': item.content.date_time || 'n/a',
      'tfp-type': item.content.type,
    })
  }

  const isExternal = item?.content?.link?.linktype === 'url'

  const dayJsDate = dayjs(
    `${item.content.date_time}+00:00`,
    'YYYY-MM-DD HH:mmZ'
  )
  const day = dayJsDate.locale(locale).format('dddd')
  const date = dayJsDate.locale(locale).format('ll')
  const time = dayJsDate.locale(locale).format('HH:mm')
  // const isInLessThan7Days = dayJsDate.subtract(7, 'days').isBefore(new Date())

  const types = {
    clinic: i18n('eveningType.clinic'),
    online: i18n('eveningType.online'),
    other: i18n('eveningType.other'),
  }

  return (
    <Item {...props}>
      <ContentWrapper style={{ flex: 1 }} variant={variant}>
        <Content>
          <Tag icon={item.content.type === 'online' ? 'world' : 'map-pointer'}>
            {toCapitalize(item.content.type)}
          </Tag>

          <Meta>
            <Text as="h2" variant="twenty/regular">
              {item.content?.title || item?.name}
            </Text>
          </Meta>

          <FullWidth>
            {item.content.date_time && (
              <div>
                <Meta description className="mb-1.5">
                  <Icon icon="calendar" className="mr-2" />
                  <Text as="span" variant="eighteen">
                    {day.slice(0, 3)}, {date}
                  </Text>
                  <Dot />
                  <Text as="span" variant="eighteen">
                    {time}
                  </Text>
                </Meta>
              </div>
            )}
            {item.content.location ? (
              <Meta description>
                <Icon
                  icon={
                    item.content.type === 'online' ? 'world' : 'map-pointer'
                  }
                  className="mr-2"
                />
                <Text as="span" variant="eighteen">
                  {item.content.location}
                  {` (${types[item?.content?.type]})`}
                </Text>
              </Meta>
            ) : (
              <Meta description>
                <Icon icon="map-pointer" />
                <Text as="span" variant="eighteen">
                  {types[item.content.type]}
                </Text>
              </Meta>
            )}
          </FullWidth>
        </Content>

        <ButtonWrapper>
          <ButtonLink
            variant="solid"
            size="small"
            isFull
            onClick={handleTrackEvent}
            {...(isExternal
              ? {
                  leftIcon: 'external',
                  target: '_blank',
                  rel: 'noopener noreferrer',
                  href: item?.content?.link?.cached_url,
                }
              : {
                  rightIcon: 'arrow-right',
                  href: `/${removeLeadingAndTrailingSlash(
                    item?.content?.link?.cached_url || item.full_slug
                  )}`,
                })}
          >
            {i18n('eventSignUp')}
          </ButtonLink>
        </ButtonWrapper>
      </ContentWrapper>
    </Item>
  )
}

const Item = styled.li<{ isDrawer?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;

  color: inherit;
  background-color: ${({ theme }) => theme.colors.background.dark};

  border-radius: 1.25rem;
  overflow: hidden;

  min-height: 10rem;

  ${({ theme }) => theme.media.md} {
    flex-direction: row;
    min-width: ${({ isDrawer }) => isDrawer && '25.8125rem'};
  }
`

const Meta = styled.div<{ description?: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ theme, description }) =>
    description
      ? addAlphaToColor(theme.colors.foreground.default, 80)
      : theme.colors.foreground.default};
`

const FullWidth = styled.div`
  width: 100%;
`

const ContentWrapper = styled.div<{ variant?: Props['variant'] }>`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${({ theme }) => theme.media.md} {
    max-width: ${({ variant }) => (variant === 'big' ? '19rem' : 'unset')};
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const ButtonWrapper = styled.div`
  margin-top: auto;
  flex-grow: 0;
  width: fit-content;
`

const Dot = styled.div`
  display: flex;
  width: 0.125rem;
  height: 0.125rem;
  background: ${({ theme }) =>
    addAlphaToColor(theme.colors.foreground.default, 80)};
  margin: 0px 0.25rem;
`
