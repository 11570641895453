import { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'

import { Icon } from './Icon'

type Props = { src: string; title?: string }

export const Video = ({ src, title, ...props }: Props): JSX.Element => {
  const [playing, setPlaying] = useState(false)
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    const video = videoRef.current

    const onPause = () => setPlaying(false)

    const onPlay = () => setPlaying(true)

    video?.addEventListener('pause', onPause)
    video?.addEventListener('playing', onPlay)

    return () => {
      video?.removeEventListener('pause', onPause)
      video?.removeEventListener('play', onPlay)
    }
  }, [])

  const handleClick = () => {
    if (videoRef.current && !playing) {
      videoRef.current.controls = true
      videoRef.current.play()
    }
  }

  return (
    <div {...props} className="relative">
      <video
        ref={videoRef}
        className="w-full rounded-4"
        src={src}
        title={title}
      />
      {!playing && (
        <IconHolder onClick={handleClick} role="button">
          <Icon icon="play" size={48} className="text-background" />
        </IconHolder>
      )}
    </div>
  )
}

const IconHolder = styled.button`
  cursor: pointer;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`
