import NextImage from 'next/image'
import { ISbStoryData } from 'storyblok-js-client'
import styled from 'styled-components'

import { Container, Text } from 'common/UI'
import useDrawer from 'common/hooks/useDrawer'
import { SatelliteClinicPageStoryblok } from 'common/types'
import { getImageAttributes, textByLine } from 'common/utils/content'

type Props = {
  story: ISbStoryData<SatelliteClinicPageStoryblok>
}

export const SatelliteClinicPageHeader = ({ story }: Props): JSX.Element => {
  const { drawer } = useDrawer()
  const isDrawer = !!drawer

  return (
    <Container variant={isDrawer ? 'full' : 'typography'}>
      <Wrapper>
        <InnerWraper>
          <div>
            {story.name && (
              <Text as="h1" variant="title/large" className="mb-5">
                {story.name}
              </Text>
            )}
          </div>
          <div>
            {story.content.description && (
              <div>
                {textByLine(story.content.description, (part) => (
                  <Text as="p" variant="eighteen" className="opacity-80">
                    {part}
                  </Text>
                ))}
              </div>
            )}
          </div>
        </InnerWraper>
        {story.content.image?.filename && (
          <ImageWrapper>
            <NextImage
              {...getImageAttributes(story.content.image)}
              style={{ objectFit: 'cover', height: '100%' }}
            />
          </ImageWrapper>
        )}
      </Wrapper>
    </Container>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding: 1.25rem 0rem 2rem 0rem;

  ${({ theme }) => theme.media.lg} {
    flex-direction: row;
    justify-content: space-between;
  }
`

const ImageWrapper = styled.div`
  border-radius: 1rem;
  overflow: hidden;
  height: 20.75rem;
  width: 100%;

  ${({ theme }) => theme.media.lg} {
    width: 17.0625rem;
  }
`

const InnerWraper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 21.5625rem;
  justify-content: space-between;
`
