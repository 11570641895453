import NextLink from 'next/link'

import { MultilinkStoryblok } from 'common/types'
import { getAnchorFromCmsLink } from 'common/utils/content'

export type StoryblokLinkProps =
  React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    link: MultilinkStoryblok | undefined
  }

export const StoryblokLink = ({
  children,
  link,
  ...rest
}: StoryblokLinkProps) => {
  const { href, target, rel } = getAnchorFromCmsLink(link)

  if (href) {
    const Component = link?.linktype === 'story' ? NextLink : 'a'

    return (
      <Component href={href} target={target} rel={rel} {...rest}>
        {children}
      </Component>
    )
  } else {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.log(`Uncaught StoryblokLink: `, link)
    }

    return <span {...rest}>{children}</span>
  }
}
