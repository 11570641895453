import { useRouter } from 'next/router'
import { ReactNode, useCallback, useEffect, useState } from 'react'

import { Breadcrumbs } from 'common/UI/Breadcrumbs'
import { getDistance } from 'common/utils/content'
import { useDataContext } from 'lib/dataContext'
import { useFetchClinicLocations } from 'modules/Blocks/ClinicLocator/useFetchClinics'
import { PageDrawer } from 'modules/Pages/PageDrawer'

import { Alert } from './Alert'
import { ClinicNudge } from './ClinicNudge'
import { FloatingCTAS } from './FloatingCTAS'
import { Footer } from './Footer'
import { PreFooter } from './PreFooter'
import { PreviewAlert } from './PreviewAlert'
import { RegionNudge } from './RegionNudge'
import { Selector } from './Selector'
import { TopNav } from './TopNav'
import { Topbar } from './Topbar'

const CLINIC_DISTANCE_THRESHOLD = 10000 // Number is in meters

export const Layout: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [modal, setModal] = useState<'region' | 'clinic' | null>(null)
  const [city, setCity] = useState<string>('')
  const [position, setPosition] = useState<{ lat: number; lng: number }>({
    lat: 0,
    lng: 0,
  })
  const [countryCode, setCountryCode] = useState<string | null>(null)
  const [hasFlagInStorage, setHasFlagInStorage] = useState<boolean>(false)
  const [shouldShowRegionNudge, setShouldShowRegionNudge] =
    useState<boolean>(false)
  const { query, isPreview, asPath } = useRouter()
  const lang = query.lang
  const isEditMode = !!query._storyblok
  const isLangHome = asPath === `/${lang}`
  const { avoidAlerts, breadcrumbs } = useDataContext()

  const fetchLocation = useCallback(async () => {
    if ((hasFlagInStorage || !!countryCode) && !isLangHome) {
      return
    }

    try {
      const res = await fetch('/api/get-location')
      const { country_code, city, latitude, longitude } = await res.json()
      setCountryCode(country_code.toLowerCase())
      setCity(city.toLowerCase())
      setPosition({ lat: latitude, lng: longitude })
    } catch (error) {
      // noop
    }
  }, [countryCode, hasFlagInStorage, isLangHome])

  useEffect(() => {
    fetchLocation()
  }, [fetchLocation])

  const { data: clinicsData } = useFetchClinicLocations()

  const nearClinics = clinicsData.filter((clinic) => {
    const clinicCoords = clinic.content.coordinates.split(',')

    if (!position || !clinicCoords) return false

    const distance = getDistance(
      position.lat,
      position.lng,
      Number(clinicCoords[0]),
      Number(clinicCoords[1])
    )

    return distance < CLINIC_DISTANCE_THRESHOLD
  })

  return (
    <>
      <PageDrawer />

      {isPreview && !isEditMode && <PreviewAlert />}
      <RegionNudge
        hasFlagInStorage={hasFlagInStorage}
        setHasFlagInStorage={setHasFlagInStorage}
        setShouldShowRegionNudge={setShouldShowRegionNudge}
        shouldShowRegionNudge={shouldShowRegionNudge}
        showRegionModal={() => setModal('region')}
        countryCode={countryCode}
      />
      {!shouldShowRegionNudge && (
        <ClinicNudge
          showClinicModal={() => setModal('clinic')}
          city={city}
          clinics={nearClinics}
          isLangHome={isLangHome}
          countryCode={countryCode}
        />
      )}
      <Topbar showRegionModal={() => setModal('region')} />
      <Selector
        isVisible={!!modal}
        variant={modal}
        city={city}
        clinics={nearClinics}
        onClose={() => {
          setModal(null)
        }}
      />
      <TopNav />
      {!avoidAlerts && <Alert />}

      <FloatingCTAS animatePosition="100%" />

      {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}

      {children}

      <PreFooter />
      <Footer showRegionModal={() => setModal('region')} />
    </>
  )
}
